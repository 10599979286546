<template>
  <div class="ranking-mini">
    <div class="title-line">
      <h2 class="title-strat">{{ title }}</h2>
      <div
        class="button-more"
        @click="openRanking"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 19.328 34.43"
        >
          <path
            d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
            fill="#fff"
            fill-rule="evenodd"
          />
        </svg>
        Voir le classement complet
      </div>
    </div>
    <p class="date">{{ dateToStringMonthYear(new Date()) }}</p>
    <div class="ranking">
      <div
        v-for="rank in ranks"
        :key="rank.user?.slug"
        class="waper-rank"
        :class="{
          'user-rank': rank.rankUser,
          'user-rank--profile': rank.rankUser && profile,
        }"
      >
        <div class="position">{{ rank.position }}</div>
        <NuxtLink
          v-if="other"
          :to="`/wapers/${rank.user?.slug}`"
          class="waper"
        >
          <div class="img-container">
            <NuxtImg
              v-if="rank.user?.avatar"
              :src="rank.user.avatar.url"
              :alt="rank.user.nickname"
              loading="lazy"
              quality="80"
              width="50"
              height="50"
              sizes="sm:50px tb:100px"
              fit="cover"
              format="auto"
            />
          </div>
          <p class="name">
            {{ readMore(capitalize(rank.user?.nickname), 25) }}
          </p>
        </NuxtLink>
        <NuxtLink
          v-else
          :to="rank.rankUser ? '/mon-profil' : `/wapers/${rank.user?.slug}`"
          class="waper"
        >
          <div class="img-container">
            <NuxtImg
              v-if="rank.user?.avatar"
              :src="rank.user.avatar.url"
              :alt="rank.user.nickname"
              loading="lazy"
              quality="80"
              width="50"
              height="50"
              sizes="sm:50px tb:100px"
              fit="cover"
              format="auto"
            />
          </div>
          <p class="name">
            {{ readMore(capitalize(rank.user?.nickname), 25) }}
          </p>
        </NuxtLink>
        <div class="level">{{ rank.rank_name }}</div>
        <div class="points">
          {{ rank.points }} {{ pluralize('pt', rank.points) }}
        </div>
      </div>
      <div
        v-if="labelBonus"
        class="bonus"
        v-html="labelBonus"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TopWapers } from '~/types'
import { rankingModal } from '~/modals/ranking'

const { gtag } = useGtag()

const props = withDefaults(
  defineProps<{
    ranks: TopWapers['wapers']
    title: string
    profile?: boolean
    other?: boolean
  }>(),
  {
    title: 'Votre classement',
    profile: false,
    other: false,
  },
)

const labelBonus = computed(() => {
  const filteredItem = props.ranks.find((rank) => !!rank.subtitle)
  if (!filteredItem) return
  return filteredItem.subtitle
})

const openRanking = () => {
  gtag('event', 'cta', {
    cta_name: 'Voir le classement complet',
  })
  rankingModal.open()
}
</script>

<style lang="scss" scoped>
.ranking-mini {
  position: relative;
  padding: 0 8rem;

  @media (min-width: 1200px) {
    min-width: 40rem;
  }

  .title-line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;

    .button-more {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      margin-left: 2rem;
      color: $white;
      font-size: 1.4rem;
      text-decoration: none;
      cursor: pointer;

      svg {
        width: 0.5rem;
        height: 0.92rem;
        margin-right: 0.5em;

        path {
          fill: $white;
        }
      }
    }
  }

  .date {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .ranking {
    position: relative;
    margin-top: 3rem;

    .waper-rank {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 7rem;
      border-top: 1px solid rgba($white, 0.3);

      &.user-rank {
        position: relative;
        background: #1071cd;
        background: linear-gradient(
          90deg,
          rgba(#1071cd, 0) 0%,
          rgb(6 97 183 / 1) 50%,
          rgba(#1071cd, 0) 100%
        );
        border-top: 0;

        &--profile {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -9.8rem;
            width: 2.1rem;
            height: 2.1rem;
            background: #011450;
            transform: rotate(45deg) translateY(-50%);
            transform-origin: center;
          }
        }

        .waper,
        .level {
          border-right: 1px solid rgba(#013d7b, 0.3);
        }

        .waper {
          font-weight: bold;
        }

        .points {
          font-size: 1.6rem;
        }
      }

      .position {
        width: 3.5rem;
        font-family: 'SST Condensed';
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.6rem;
      }

      .waper {
        display: flex;
        align-items: center;
        width: 19rem;
        height: 100%;
        text-decoration: none;
        border-right: 1px solid rgba(#fff, 0.3);

        .img-container {
          width: 5rem;
          height: 5rem;
          margin-right: 1.3rem;
          overflow: hidden;
          background: rgba(#000, 0.2);
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          color: $white;
          font-size: 1.1rem;
          text-decoration: none;
        }
      }

      .level {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 9.7rem;
        height: 100%;
        font-size: 1rem;
        border-right: 1px solid rgba(#fff, 0.3);
      }

      .points {
        flex-grow: 1;
        padding-left: 1rem;
        font-family: 'SST Condensed';
        font-size: 1.4rem;
        font-weight: bold;
        text-align: right;
      }
    }
  }

  .bonus {
    position: absolute;
    right: -8rem;
    bottom: -3rem;
    height: 3rem;
    padding-right: 8rem;
    padding-left: 2rem;
    font-size: 1.4rem;
    line-height: 3rem;
    background: $orange;
    border-bottom-left-radius: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .ranking-mini {
    .title-line {
      padding: 0;
    }
  }
}

@media screen and (max-width: 560px) {
  .ranking-mini {
    padding-right: 0;
    padding-left: 2.5rem;

    .title-line {
      position: relative;
      margin-bottom: 2.8rem;

      .button-more {
        position: absolute;
        top: 4rem;
        right: 2.5rem;
      }
    }

    .bonus {
      right: 0;
      padding-right: 2.5rem;
    }

    .ranking {
      .waper-rank {
        .waper {
          flex-grow: 1;
          font-size: 1.1rem;
        }

        .points {
          display: flex;
          flex-grow: 0;
          justify-content: center;
          align-items: center;
          width: 12rem;
          font-size: 1.4rem;
        }

        .level {
          display: none;
        }
      }
    }
  }
}
</style>
