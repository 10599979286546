<template>
  <div class="main">
    <div class="p-win">
      <span>À gagner sur</span>
      <img
        src="~/assets/img/ps-store.svg"
        alt="À gagner sur PlayStation Store"
      />
    </div>
    <div class="p-infos">
      <div class="p-info info--gold">
        <div class="p-info__badge">
          <div class="p-info__medal gold">
            <img src="~/assets/icons/gold.svg" />
          </div>
          <span>15€</span>
          de réduction*
        </div>
        <div class="p-info__rank">
          De la <strong>1<sup>ère</sup></strong> à<br />la
          <strong>20<sup>ème</sup></strong> place
        </div>
      </div>
      <div class="p-info info--silver">
        <div class="p-info__badge">
          <div class="p-info__medal silver">
            <img src="~/assets/icons/silver.svg" />
          </div>
          <span>10€</span>
          de réduction*
        </div>
        <div class="p-info__rank">
          De la <strong>21<sup>ème</sup></strong> à<br />la
          <strong>60<sup>ème</sup></strong> place
        </div>
      </div>
      <div class="p-info info--bronze">
        <div class="p-info__badge">
          <div class="p-info__medal bronze">
            <img src="~/assets/icons/bronze.svg" />
          </div>
          <span>5€</span>
          de réduction*
        </div>
        <div class="p-info__rank">
          De la <strong>61<sup>ème</sup></strong> à<br />la
          <strong>270<sup>ème</sup></strong> place
        </div>
      </div>
    </div>
    <select
      id="month"
      v-model="currentMonth"
      name="month"
      class="c-form__select p-select"
      @change="changeMonth"
    >
      <option
        v-for="month in months"
        :key="month"
        :value="month"
      >
        {{ capitalize(monthLabel(month)) }}
      </option>
    </select>
    <div
      class="hr"
      :class="{ 'is-loading': loading }"
    />
    <div
      ref="infiniteWapers"
      class="p-wapers"
    >
      <div
        v-for="waper in wapers"
        :key="waper.user?.nickname"
      >
        <div class="p-waper">
          <div class="p-waper__position">
            {{ waper.position }}
          </div>
          <NuxtLink
            :to="`${
              authenticated &&
              waper.user?.nickname === auth.data.value?.nickname
                ? '/mon-profil'
                : `/wapers/${waper.user?.slug}`
            }`"
            class="p-waper__link"
          >
            <NuxtImg
              :src="waper.user?.avatar?.url"
              class="p-waper__avatar"
              loading="lazy"
              quality="80"
              width="84"
              height="84"
              sizes="xs:60px sm:84px tb:168px"
              fit="cover"
              format="auto"
            />
            <div class="p-waper__infos">
              <p class="p-waper__nickname">{{ waper.user?.nickname }}</p>
              <span class="p-waper__rank">{{ waper.rank_name }}</span>
            </div>
          </NuxtLink>
          <div class="p-waper__points">
            {{ waper.points }} {{ pluralize('point', waper.points) }}
          </div>
        </div>
        <hr />
      </div>
      <div>
        <FakeWaper v-if="isLoadingRanks" />
      </div>
    </div>

    <div
      v-if="waperUser?.points !== null && waperUser?.user"
      class="p-user"
    >
      <div class="p-waper p-waper--user">
        <div class="p-waper__position">
          {{ waperUser.position }}
        </div>
        <NuxtLink
          to="/mon-profil"
          class="p-waper__link"
        >
          <NuxtImg
            :src="waperUser.user?.avatar?.url ?? ''"
            class="p-waper__avatar"
            loading="lazy"
            quality="80"
            width="84"
            height="84"
            sizes="xs:60px sm:84px tb:168px"
            fit="cover"
            format="auto"
          />
          <div class="p-waper__infos">
            <p class="p-waper__nickname">
              {{ waperUser?.user?.nickname ?? '' }}
            </p>
            <span class="p-waper__rank">{{ waperUser.rank_name }}</span>
          </div>
        </NuxtLink>
        <div class="p-waper__points">
          {{ waperUser.points }} {{ pluralize('point', waperUser.points) }}
        </div>
      </div>
      <div
        v-if="waperUser?.subtitle"
        class="p-user__infos"
      >
        <p v-html="waperUser.subtitle" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format, addMonths } from 'date-fns'
import { fr } from 'date-fns/locale/fr'
import type { CustomPostRanking, Endpoints } from '~/types'

const auth = useAuth()
const infiniteWapers = ref<HTMLElement | null>(null)

const loading = ref(false)
const wapers = ref<Endpoints['GET__RANKING_SEARCH']['results']>([])
const months = ref<number[]>([])
const currentMonth = ref(0)
const page = ref(1)
const waperUser = ref<Endpoints['GET__RANKING_SECURE_USER']>()

const { authenticated } = useAuthenticated()

const fetchMonths = async () => {
  loading.value = true

  const { data, error } = await useCustomFetch<Endpoints['GET__RANKING_LIST']>(
    API.GET__RANKING_LIST,
  )

  const rawData = unref(data)
  if (error.value || !rawData) {
    return logError(
      'from Ranking Modal',
      'There was a problem fetching months: ',
      error,
    )
  }

  months.value = rawData.months

  if (authenticated.value) {
    const { data } = await useCustomFetch<
      Endpoints['GET__RANKING_SECURE_USER']
    >(API.GET__RANKING_SECURE_USER, {
      query: {
        month: currentMonth,
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      return logError(
        'from Ranking Modal',
        'There was a problem fetching user ranking: ',
        error,
      )
    }

    waperUser.value = rawData
  }
  loading.value = false
}

fetchMonths()

const pagesCount = ref<number>()

const canLoadMoreComputed = computed(() => {
  return !pagesCount.value
    ? true
    : page.value < pagesCount.value && !isLoadingRanks.value
})

const canLoadMore = () => {
  return canLoadMoreComputed.value
}

const isLoadingRanks = ref(false)

const infiniteHandler = async () => {
  loading.value = true
  isLoadingRanks.value = true

  const { data, error } = await useCustomFetch<
    Endpoints['GET__RANKING_SEARCH']
  >(API.GET__RANKING_SEARCH, {
    query: {
      page: page.value,
      month: currentMonth.value,
    },
  })

  const rawData = unref(data)
  if (error.value || !rawData) {
    return logError(
      'from Ranking Modal',
      'There was a problem getting ranking search results: ',
      error,
    )
  }

  if (pagesCount.value === undefined) {
    pagesCount.value = rawData.pages_count
  }

  wapers.value.push(...rawData.results)
  if (page.value < rawData.pages_count) {
    page.value = page.value + 1
  }

  loading.value = false
  isLoadingRanks.value = false
}

const modal = () => document.querySelector('.modal') as HTMLElement

onMounted(async () => {
  await nextTick()

  useInfiniteScroll(modal, infiniteHandler, {
    distance: 100,
    canLoadMore,
  })
})

const monthLabel = (val = 0) => {
  const d = addMonths(new Date(), val)

  return format(d, 'LLLL y', {
    locale: fr,
  })
}

const changeMonth = async () => {
  loading.value = true
  page.value = 1
  wapers.value = []

  const { data, error } = await useCustomFetch<
    Endpoints['GET__RANKING_SEARCH']
  >(API.GET__RANKING_SEARCH, {
    query: {
      page: page.value,
      month: currentMonth.value,
    },
  })

  const rawData = unref(data)
  if (error.value || !rawData) {
    loading.value = false

    return logError(
      'from Ranking Modal',
      'There was a problem fetching search ranking: ',
      error,
    )
  }

  wapers.value = rawData.results
  page.value = page.value + 1

  if (authenticated.value) {
    const { data, error } = await useCustomFetch<CustomPostRanking>(
      API.GET__RANKING_SECURE_USER,
      {
        query: {
          month: currentMonth.value,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      loading.value = false

      return logError(
        'from Ranking Modal',
        'There was a problem fetching search ranking: ',
        error,
      )
    }

    waperUser.value = rawData
  }
  loading.value = false
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  padding-bottom: 12rem;
}

hr {
  margin: 1.5rem 0;
  border-bottom: 0;
  opacity: 0.3;
}

.hr {
  position: relative;
  height: 1px;
  margin: 1.5rem 0;
  overflow: hidden;
  background-color: rgb(0 0 0 / 0.3);

  &.is-loading {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -25%;
      display: block;
      width: 25%;
      height: 100%;
      background-color: $blue;
      animation: loading 0.7s infinite linear;
    }
  }
}

.backdrop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

@keyframes loading {
  0% {
    left: -25%;
  }

  90%,
  100% {
    left: 100%;
  }
}

.p-user {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;

  @media (min-width: 992px) {
    width: 61rem;
  }

  &__infos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 0;
    color: $white;
    text-align: center;
    background-color: $orange;
  }
}

.p-waper {
  display: flex;
  align-items: center;

  &--user {
    padding: 2rem;
    color: $white;
    background-color: $blue;

    @media (min-width: 992px) {
      width: 61rem;
      padding: 2rem 8rem;
    }
  }

  &__position {
    flex-shrink: 0;
    flex-basis: 3rem;
    font-family: 'SST Condensed';
    font-size: 1.2rem;
    font-weight: 700;

    @media (min-width: 992px) {
      flex-basis: 5rem;
      font-size: 1.4rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  &__avatar {
    flex-shrink: 0;
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
    border-radius: 50%;
    object-fit: cover;

    @media (min-width: 992px) {
      width: 8.4rem;
      height: 8.4rem;
      margin-right: 2rem;
    }
  }

  &__infos {
    margin-right: 1rem;
  }

  &__nickname {
    margin-bottom: 0.2rem;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__rank {
    font-size: 1.4rem;
    font-weight: 300;
    opacity: 0.5;
  }

  &__points {
    flex-shrink: 0;
    margin-left: auto;
    font-family: 'SST Condensed';
    font-size: 1.6rem;
    font-weight: 700;

    @media (min-width: 992px) {
      font-size: 1.8rem;
    }
  }
}

.p-win {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4rem;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-end;
  }

  span {
    flex-shrink: 0;
    margin-right: 2rem;
    color: $blue;
    font-family: 'SST Condensed';
    font-size: 2rem;
    line-height: 1.2;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  img {
    height: 2rem;

    @media (min-width: 992px) {
      height: 3rem;
    }
  }
}

.p-infos {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: 360px) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 3rem;
  }

  @media (min-width: 992px) {
    margin-bottom: 5rem;
  }
}

.p-info {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  @media (min-width: 360px) {
    flex-direction: column;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    width: 14rem;
  }

  &__badge {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    margin-right: 3rem;
    font-family: 'SST Condensed';
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    background-image: linear-gradient(to bottom right, #ded2ca, #f4f4f4);
    border-radius: 50%;
    box-shadow: 0 0 0 0.8rem rgba($orange, 0.3);

    @media (min-width: 360px) {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    @media (min-width: 992px) {
      width: 12rem;
      height: 12rem;
      font-size: 1.2rem;
      box-shadow: 0 0 0 1rem rgba($orange, 0.3);
    }

    span {
      margin-bottom: -0.5rem;
      color: $orange;
      font-family: SST;
      font-size: 3rem;
      font-weight: 900;
      line-height: 1.1;

      @media (min-width: 992px) {
        font-size: 4.5rem;
      }
    }
  }

  &__medal {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    border-width: 0.3rem;
    border-style: solid;
    border-radius: 50%;

    @media (min-width: 992px) {
      top: -0.2rem;
      left: -0.2rem;
      width: 3.3rem;
      height: 3.3rem;
    }

    &.gold {
      background: #fed02f;
      border-color: #ffe99d;

      img {
        width: 1.3rem;
        height: 1.3rem;
      }
    }

    &.silver {
      background: #aaa;
      border-color: #cbcbcb;

      img {
        width: 0.854rem;
        height: 1.403rem;
      }
    }

    &.bronze {
      background: #d5831f;
      border-color: #f0ab56;

      img {
        width: 0.897rem;
        height: 0.897rem;
      }
    }
  }

  &__rank {
    font-family: 'SST Condensed';
    font-size: 1.3rem;
    line-height: 1.3;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (min-width: 992px) {
      font-size: 1.4rem;
      text-align: center;
    }

    sup {
      font-size: 1rem;
      vertical-align: top;
    }
  }
}

.p-select {
  height: 2rem;
  padding: 0 4rem 0 0 !important;
  border: 0;

  &:focus {
    box-shadow: none;
  }
}
</style>
