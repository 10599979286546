<template>
  <div class="animated-bg">
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
  </div>
</template>

<style lang="scss" scoped>
.animated-bg {
  position: relative;
  height: 110px;
  overflow: hidden;
  background-color: #ddd;
  background-image: linear-gradient(to right, #eee 10%, #ddd 20%, #eee 33%);
  background-size: 200%;
  animation: placeholder 1.25s infinite linear;
  animation-fill-mode: forwards;
}

@keyframes placeholder {
  0% {
    background-position: 300% 50%;
  }

  100% {
    background-position: -100% 50%;
  }
}

.bg-masker {
  position: absolute;
  background-color: #fff;

  &:nth-child(1) {
    top: 0;
    left: 0;
    width: 2rem;
    height: 35%;
  }

  &:nth-child(2) {
    bottom: 0;
    left: 0;
    width: 2rem;
    height: 35%;
  }

  &:nth-child(3) {
    top: 0;
    left: 2rem;
    width: 5rem;
    height: 100%;
  }

  &:nth-child(4) {
    top: -1.5rem;
    left: 4rem;
    width: 14rem;
    height: 14rem;
    background-color: transparent;
    border: 3rem solid $white;
    border-radius: 50%;
  }

  &:nth-child(5) {
    top: 0;
    left: 15rem;
    width: 3rem;
    height: 100%;
  }

  &:nth-child(6) {
    top: 0;
    left: 18rem;
    width: calc(100% - 18rem);
    height: 3rem;
  }

  &:nth-child(7) {
    bottom: 0;
    left: 18rem;
    width: calc(100% - 18rem);
    height: 3rem;
  }

  &:nth-child(8) {
    top: 3rem;
    left: 30rem;
    width: 8rem;
    height: 2rem;
  }

  &:nth-child(9) {
    top: 5rem;
    left: 18rem;
    width: calc(100% - 18rem);
    height: 1rem;
  }

  &:nth-child(10) {
    top: 6rem;
    left: 24rem;
    width: calc(100% - 24rem);
    height: 2rem;
  }
}
</style>
