import RankingModal from '@/components/modal/RankingModal.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'

export const rankingModal = useCustomModal({
  attrs: {
    modalId: 'ranking',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: RankingModal,
    }),
  },
})
